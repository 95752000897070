<template>
  <menu-tab
    :menu="menu"
    :swiper="true"
    :slidesPerView="5"
    :initIndex="index"
    @change="selectMenu"
  ></menu-tab>
    <list
       url="/M/Home/OpenSearchMore"
      :filterFunc="productMap"
      :params="{ type: 'product', keyword: keyword }"
      :show="currentMenuItem == '产品'"
    >
      <template v-slot:default="data">
        <product-new-card :list="data" tid="0" name="productdetail" typeFlag="search"></product-new-card>
      </template>
    </list>
  <list
    url="/M/Home/OpenSearchMore"
    :filterFunc="instrumentMap"
    :params="{ type: 'instrument', keyword: keyword }"
    :show="currentMenuItem == '仪器'"
  >
    <template v-slot:default="data">
       <yiqi :list="data" name="instrumentdetail"></yiqi>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'news', keyword: keyword }" :show="currentMenuItem == '新闻'">
    <template v-slot:default="data">
      <news-item :newData="data"></news-item>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'topic', keyword: keyword }" :show="currentMenuItem == '帖子'">
    <template v-slot:default="data">
      <topic-item :topicData="data"></topic-item>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'course', keyword: keyword }" :show="currentMenuItem == '课堂'">
    <template v-slot:default="data">
      <class-item :course="data"></class-item>
    </template>
  </list>

</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";
  import { useRoute } from "vue-router";
  import MenuTab from "@/components/common/MenuTab.vue";
  import list from "@/components/common/list.vue";

  import productNewCard from "@/components/common/productNewCard.vue";
  import productCard from "@/components/common/productCard.vue";
  import bnccProductCard from "@/components/common/bnccProductCard.vue";
  import newsItem from "@/components/common/NewsItem.vue";
  import TopicItem from "@/components/common/TopicItem.vue";
  import ClassItem from "@/components/common/ClassItem.vue";
  import Toast from "@/components/UI/Toast/";
  import yiqi from "@/components/common/yiqiItem.vue";

//ts
import { instrumentMap, productMap, strainOrCellsMap } from "@/hooks/useProduct";

export default defineComponent({
  name: "OpenSearch",
  components: {
    MenuTab,
    productNewCard,
    productCard,
    list,
    newsItem,
    TopicItem,
    ClassItem,
    yiqi
  },
  setup() {
    //  切换列表菜单
    type Menu =  "产品" | "仪器" | "新闻" | "课堂" | "帖子" ;
    const menu: Menu[] = [
      "产品",
      "仪器",
      "新闻",
      "课堂",
      "帖子",
    ];

    const route = useRoute();
    const type = route.query.type;
    let index = 0;
    if (type) {
      index = menu.findIndex((item) => {
        return item == type;
      });
    }

    const keyword = route.query.keyword;
    const currentMenuItem = ref(menu[index]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')
    return {
      menu,
      currentMenuItem,
      selectMenu,
      index,
      instrumentMap,
      productMap,
      keyword,
      strainOrCellsMap,
    };
  },
});
</script>

