<template >
  <transition-group tag="ul" name="list">
    <li class="news-li" v-for="item in newData.data" :key="item.id">
      <router-link :to="{ name: 'NewsDetail', query: { newsid: item.id } }">
        <div class="side-view">
          <img :src="item.pic" alt="" />
        </div>
        <div class="news-info">
          <div class="news-title" v-html="item.newTitle"></div>
          <div class="author-info">
            <div class="author">作者：{{ item.author }}</div>
            <div class="date">{{ dateFormat(item.createTime) }}</div>
          </div>
          <div class="operation-info">
            <span>
              <img
                class="view-img"
                src="@/assets/images/icon-liulan.png"
                alt="浏览数："
              />
              {{ item.clickCount }}
            </span>
            <span>
              <img
                class="recommend-img"
                src="@/assets/images/icon-pinglun.png"
                alt="评论数："
              />
              {{ item.commentCount || 0 }}
            </span>
            <span>
              <img
                class="zan-img"
                src="@/assets/images/icon-dianzan.png"
                alt="点赞数："
              />
              {{ item.likeCount }}
            </span>
          </div>
        </div>
      </router-link>
    </li>
  </transition-group>
  <transition-group tag="ul" v-if="newData.loading" name="list">
    <li class="news-li skeleton" v-for="(item, index) in 10" :key="index">
      <div class="side-view skeleton-bg"></div>
      <div class="news-info">
        <div class="news-title skeleton-bg"></div>
        <div class="author-info">
          <div class="author skeleton-bg"></div>
          <div class="date skeleton-bg"></div>
        </div>
        <div class="operation-info skeleton-bg"></div>
      </div>
    </li>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
export default defineComponent({
  props: {
    newData: Object,
    loading: Boolean,
  },
  setup(props) {
    return {
      dateFormat,
    };
  },
});
</script>

<style lang='scss' scoped>
.module .news-li {
  width: 100%;
  padding: 15px 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #999;
  padding-left: 130px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
}

.module .news-li a {
  color: #666;
  font-size: 14px;
}

.module .news-info .news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  top: 3px;
  height: 40px;
  line-height: 20px;
}

.module .news-li a:visited .news-title {
  color: #999 !important;
}

.module .author-info {
  overflow: hidden;
  margin: 7px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-top: 9px;
}

.module .author {
  margin-right: 16px;
  color: #999;
  line-height: 17px;
}
.module .date {
  line-height: 17px;
  color: #999;
}
.module .operation-info {
  display: flex;
  align-items: center;
}
.module .operation-info span {
  display: flex;
  align-items: center;
  height: 17px;
  color: #999;
}
.module .operation-info img {
  width: 16px;
  margin-left: 16px;
  margin-right: 5px;
}

.module .operation-info .view-img {
  width: 20px;
  margin-left: 0;
}

.module .operation-info .zan-img {
  width: 14px;
}
.module .side-view {
  position: absolute;
  left: 10px;
  top: 20px;
}
.module .side-view img {
  width: 110px;
  height: 80px;
  object-fit: cover;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  .side-view {
    width: 110px;
    height: 80px;
  }
  .news-info {
    .news-title {
      height: 20px;
      margin-bottom: 15px;
    }
  }

  .author-info {
    margin: 15px 0;
    .author {
      width: 83px;
      height: 17px;
    }
    .date {
      width: 80px;
      height: 17px;
    }
  }
  .operation-info {
    width: 100%;
    height: 17px;
  }
  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}
</style>
