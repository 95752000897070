import { reactive, ref, watch, onMounted, onUnmounted, onActivated, onDeactivated } from 'vue'
import axios from '@/api/axios'
import Toast from "@/components/UI/Toast/";
import { throttle } from '@/utils/utils'


//取滚动条到底部距离
function getScrollTop() {
    let scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
        scrollTop = document.body.scrollTop;
    }
    return scrollTop;
}
//取页面可视区域高度
function getClientHeight() {
    let clientHeight = 0;
    if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight);
    } else {
        clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight);
    }
    return clientHeight;
}
//取文档整体高度
function getScrollHeight() {
    return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
}





interface ListData {
    success: boolean;
    obj: Array<object>;
    status: number;
    msg: string;
}

interface ReturnData {
    data: ListData;
    statusText: string;
    status: number;
}


export interface PropsType {
    filterFunc?: (params: object) => object;
    url: string;
    params?: object;
    show?: boolean;
    typeIndex?: number;
}



const pageScroll = function (props: PropsType) {
    const loading = ref<boolean>(true);
    let error = reactive({})
    const threshold = 200;
    const result: Array<object> = reactive([])
    const totalResult: Array<object> = reactive([])
    const page = ref(0);
    const empty = ref(false)
    const isLoadMore = ref(true)

    let isRefuseLoad = false
    watch(page, async function () {
        loading.value = true
        try {
            const data: ReturnData = await axios.get(props.url, {
                params: {
                    ...props.params,
                    page: page.value,
                }
            })
            if (data.data.success) {
                totalResult.push(data.data)
                if (data.data.obj.length) {
                    if (props.filterFunc) {
                        data.data.obj.forEach(item => {
                            result.push((props.filterFunc as (params: object) => object)(item))
                        })
                    } else {
                        data.data.obj.forEach(item => {
                            result.push(item)
                        })
                    }
                    loading.value = false
                } else {


                    if (page.value === 1) {
                        empty.value = true
                    }
                    if (props.show) {
                        //  首页即为空，则不提示

                        if (page.value === 1) {
                            empty.value = true
                        } else {
                            Toast({
                                title: '已经到底了！',
                                type: 'success',
                            })

                        }

                        loading.value = false
                    } else {
                        loading.value = false
                    }
                    isLoadMore.value = false

                }

            } else {

                Toast({
                    title: data.data.msg,
                    type: 'error'
                })

                loading.value = false
            }

        } catch (e) {
            // Toast({
            //     title: '服务器错误，请联系管理员',
            //     type: 'error'
            // })
            error = e
            // 加载失败，停止重新加载
            isRefuseLoad = true
            loading.value = false
        }
    })

    page.value++


    const func = throttle(function () {

        if (getScrollTop() + getClientHeight() + threshold >= getScrollHeight() && !loading.value) {
            if (!isLoadMore.value) {
                if (props.show) {
                    Toast({
                        title: '已经到底了！',
                        type: 'success',
                    })
                }

            } else {
                if (!isRefuseLoad) {
                    page.value++
                }

            }

        }
    }, 300)




    onMounted(() => {
        window.addEventListener('scroll', func, true)


    })
    onActivated(() => {
        window.addEventListener('scroll', func, true)
    })
    onDeactivated(() => {


        window.removeEventListener('scroll', func, true)
    })
    onUnmounted(() => {

        window.removeEventListener('scroll', func, true)
    })





    return {
        totalResult,
        result,
        loading,
        error,

        empty
    }


}


export default pageScroll

