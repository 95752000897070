import { reactive } from 'vue'

//标准物质
export interface IproductList {
    id: number;
    classID: string;
    pic: string;
    cnname: string;
    specification: string;
    catno: string;
    catnoname:string;
    concentration: string;
}
//仪器
export interface IinstrumentList {
    id: number;
    picurl: string;
    proName: string;
    proNumber: string;
    proIntro: string;
    descInfo: string;
}

//菌种 -- 细胞
export interface IstrainOrCellsList {
    id: number;
    pic: string;
    nclassid: string;
    strainDesignations: string;
    bnccid: string;
    speciesGenus: string;
}

//标准
export interface IsstandardList {
    id: number;
    cnName: string;
    standardType: string;
    classPicUrl: string;
    issueDept: string;
    chinaStdType: string;
    statusName: string;
    publishDate: string;
}

export function productMap(data: IproductList): object{
    let result: object = reactive({})
    result = {
        ...data,
        id: data.id,
        classId: data.classID,
        img: data.pic,
        title: data.cnname,
        // info: data.specification ? data.catno + "," + data.specification : data.catno,
        // brand: "标准值：" + data.concentration
        catnoname: data.catno.replace(/<\/?.+?>/g,"").replace(/ /g,""), //此处用于搜索去除标签使用
        catno: data.catno,  // 编号
        brand: data.concentration,  // 标准值
        specification: data.specification  // 规格
    }
    return result
}

export function instrumentMap(data: IinstrumentList): object{
    let result: object = reactive({})
    result = {
        ...data,
        id: data.id,
        img: data.picurl,
        title: data.proName,
        info: data.proNumber,
    }
    return result
}

export function strainOrCellsMap(data: IstrainOrCellsList): object{
    let result: object = reactive({})
    result = {
        ...data,
        id: data.id,
        classId: data.nclassid,
        img: data.pic,
        title: data.strainDesignations,
        info: data.bnccid + "," + "品牌：BNCC",
        brand: "种属：" + data.speciesGenus
    }


    return result
}

//标准
export function standardMap(data: IsstandardList): object{
    let result: object = reactive({})
    const publishdate: string = data.publishDate ? data.publishDate : "暂无信息";
    result = {
        ...data,
        id: data.id,
        classId: data.standardType,
        img: data.classPicUrl,
        title: data.cnName,
        info: data.issueDept ? data.chinaStdType + "," + data.issueDept : data.chinaStdType,
        brand: "标准状态：" + data.statusName + publishdate
    }
    return result
}

