<template>
  <div class="product-list">
    <transition-group tag="ul" name="list">
      <li  class="product-li" v-for="item in list.data" :key="item">


        <template v-if="item.catnoname.indexOf('BNCC') == -1">
          <router-link :to="{ name: 'productdetail', params: { id: item.id, classId: item.classId ? item.classId : item.nclassId} }">
            <div class="product-img">
              <img :src="item.img"/>
            </div>
            <div class="product-info-wrap">
              <div class="product-title">
                <span v-html="item.title"></span>
                <img
                    v-if="tid"
                    src="@/assets/images/icon-gw.png"
                    @click.prevent="addShopCar(item.id, tid, 1, 0,0, item.stock,0,(userInfo?userInfo.id:''))"
                />
              </div>
              <div class="product-card-info" v-html="item.info" v-if="item.info"></div>
              <div class="product-card-brand">
                <span v-if="item.specification">{{ item.specification }} <template
                    v-if="item.brand">;</template> {{ item.brand }}</span>
                <span v-else>{{ item.brand }}</span>
              </div>
              <div class="product-card-brand" v-if="!item.info">
                <span v-if="item.stock && item.stock>0" :class="typeFlag=='search'?'product-spot':''"
                      style="color:#20c2d6;">现货</span>
                <span v-else>
                <template v-if="item.specialDelivery && item.specialDelivery!=''">货期{{item.specialDelivery}}</template>
                <template v-else-if="item.catno && item.catno.includes('SHAM')">货期一周</template>
                <template v-else-if="item.catno && item.catno.includes('BWS')">货期两周</template>
                <template v-else-if="item.suppliercode && item.suppliercode==1">货期3天</template>
                  <template v-else>货期一周</template>
                </span>
                <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                  <span v-if="item.suppliercode==1">
                     <span class="price" v-if="userInfo && userInfo.discount && userInfo.discount <1 && userInfo.isDealer && userInfo.isDealer==2">
                      ￥{{ Math.ceil(Number((item.salePrice * (userInfo.discount ? userInfo.discount : 1)).toFixed(2))) || '' }}
                    </span>
                    <span class="price" v-else-if="userInfo && userInfo.discount && userInfo.discount <1 ">
                      ￥{{ Math.ceil(Number((item.retailPrice * (userInfo.discount ? userInfo.discount : 1)).toFixed(2))) || '' }}
                    </span>
                    <span class="price" v-else>
                      ￥{{ Number(item.retailPrice) || '' }}
                    </span>
                  </span>
                  <span class="price" v-else>
                    ￥{{ Number(item.retailPrice) || '' }}
                  </span>
                </template>
              </div>
            </div>
          </router-link>
        </template>


        <template v-else>
          <router-link :to="{ name: 'bnccdetail', params: { id: item.bid, classId: item.classId ? item.classId : item.nclassId} }">
            <div class="product-img">
              <img  :src="item.pictureUrl?item.pictureUrl:'https://beinabncc.oss-cn-beijing.aliyuncs.com/News/14b5205366e645649e9499501adcf284.png'" />
            </div>
            <div class="product-info-wrap">
              <div class="product-title">
                <span v-html="item.title"></span>
                <!--addShopCar(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number, nclassId: number,userId:string)-->
                <!--<img
                        src="@/assets/images/icon-gw.png"
                        @click.prevent="addShopCar(item.bid,item.anclassId, 1,
                item.anclassId==1?0
                  :((item.anclassId==2&&item.nclassId==8&&item.productFormat && item.productFormat.includes('冻存管'))?19
                    :(item.nclassId==8 ?3:0)),
                item.anclassId==1?7:(item.nclassId==8 ?10:0), item.stock,0,(userInfo?userInfo.id:''))"
                />-->
                <img src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/common/cpxq.png">
              </div>

              <div class="product-card-info" v-html="item.info" v-if="item.info"></div>
              <div class="product-card-info">
                <span v-html="item.catno"></span>
                <span class="wy-brand" v-if="item.catno && item.catno.includes('BNCC')">BNCC</span>
              </div>
              <div class="product-card-brand">
              <span v-if="item.specification">{{ item.specification }} <template
                      v-if="item.brand">;</template> {{ item.brand }}</span>
                <span v-else>{{ item.brand }}</span>
              </div>
              <div class="product-card-brand" v-if="!item.info">
                <span v-if="item.stock && item.stock>0" :class="typeFlag=='search'?'product-spot':''"
                      style="color:#20c2d6;">现货</span>
                <span v-else>
                  <template>货期一周</template>
                </span>
                  <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                    <span class="price" v-if="userInfo && userInfo.cellDiscount <1 && item.anclassId==1 ">
                      ￥{{ Math.ceil(Number(item.retailPrice * userInfo.cellDiscount).toFixed(2)) || '' }}
                    </span>
                    <span class="price" v-else-if="userInfo && userInfo.bnccDiscount <1 && item.anclassId!=1">
                      ￥{{ Math.ceil(Number(item.retailPrice * userInfo.bnccDiscount).toFixed(2)) || '' }}
                    </span>
                    <span class="price" v-else>￥{{ Number(item.retailPrice) || '' }}</span>
                  </template>
              </div>
            </div>
          </router-link>
        </template>

      </li>
    </transition-group>
    <transition-group tag="ul" v-if="list.loading" name="list">

      <li class="product-li skeleton" v-for="item in 10" :key="item">
        <div class="product-img skeleton-bg"></div>
        <div class="product-info-wrap">
          <div class="product-title skeleton-bg"></div>
          <div class="product-card-info skeleton-bg"></div>
          <div class="product-card-brand skeleton-bg"></div>
        </div>
      </li>
    </transition-group>
  </div>
  <div v-if="list.data.length==0 && !list.loading && typeFlag=='search'" class="save-area">
    <router-link
        class="save"
        :to="{name: 'customize'}"
    >
      产品定制
    </router-link>
    <a href="javascript:;" @click="back">返回</a>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, isReactive, reactive, inject, watch} from "vue";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import {useRouter} from "vue-router";

export default defineComponent({
  props: {
    list: Object,
    name: String,
    tid: {
      type: String,
      default: "",
    },
    anclassId: {
      type: String,
      default: "",
    },
    typeFlag: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()
    const router = useRouter();

    watch(
        () => {
          return props.list
        },
        (NewsVue) => {
          biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
        }
    )

    //  add shop car
    const {addShopCar} = useShopCar();

    function back() {
      router.go(-1);
    }

    return {
      addShopCar,
      userInfo,
      biaowuPriceOpen,
      back
    };
  },
});
</script>

<style lang="scss" scoped>
.product-ul {
  overflow: hidden;
}

// list data

.product-li {
  width: 100%;
  height: 100%;
  padding: 15px 10.012px;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f4f4;

  .product-img {
    width: 73px;
    height: 73px;
    float: left;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .product-info-wrap {
    height: 73px;
    margin-left: 2.15rem;
  }

  .product-title {
    // font-size: 16px;
    font-size: 14.8px;
    // color: #333;
    color: #111;
    line-height: 17.5px;
    // height: 32px;
    padding-right: 42.375px;
    box-sizing: border-box;
    // margin-bottom: 8px;
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    img {
      position: absolute;
      // right: 23px;
      right: 12px;
      top: 0;
      width: 17px;
      height: 16px;
      z-index: 1;
    }
  }

  .product-spot {
    color: #20c2d6;
  }

  .product-card-info {
    // font-size: 14px;
    font-size: 12px;
    color: #999;
    line-height: 16px;
    // margin-bottom: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;

    // padding-right: 42.375px;
    width: 92%;
    // display: flex;
    // justify-content: space-between;
    .wy-brand {
      background: #b9135a;
      color: #fff;
      padding: 0 5px;
      margin-left: 10px;
      font-size: 10px;
    }

    .price {
      color: #b9135a;
    }
  }

  .product-card-brand {
    color: #999;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    // font-size: 14px;
    font-size: 12px;
    color: #999;
    // line-height: 16px;
    line-height: 19px;

    .price {
      color: #b9135a;
      margin-left: 10px;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  .product-card-info {
    height: 16px;
    width: 70% !important;
  }

  .product-card-brand {
    height: 14px;
    width: 50% !important;
  }

  .skeleton-bg {
    background: linear-gradient(
            90deg,
            hsla(0, 0%, 74.5%, 0.2) 25%,
            hsla(0, 0%, 50.6%, 0.24) 37%,
            hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}

.save-area {
  width: 100%;
  height: 45px;
  position: relative;
  top: 50px;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 58%;
}

.save-area a {
  display: inline-block;
  width: 35%;
  height: 45px;
  background-color: #999;
  // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3.75px;
  line-height: 45px;
  text-align: center;
  color: #ffffff !important;
  font-size: 15px;
  border-radius: 25px;
}

.save-area .save {
  width: 35%;
  height: 45px;
  background-color: #b9135a;
  // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3.75px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 15px;
  border-radius: 25px;
}
</style>
<style>
.wy-brand {
  background: #b9135a;
  color: #fff;
  padding: 0 5px;
  margin-left: 10px;
  font-size: 10px;
}
</style>
