<template>
  <div class="menu-nav-wapper">
    <div class="menu-nav" v-if="swiper">
      <div class="swiper-container" id="menu-nav-swiper">
        <div class="swiper-wrapper" v-if="menu && menu.length">
          <div
            class="swiper-slide"
            v-for="(item, index) in menu"
            :key="index"
            @click="selectMenu(item)"
          >
            <a
              href="javascript:;"
              :class="currentMenuItem == item ? 'active' : ''"
              >{{ item }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <ul class="menu-nav" v-else>
      <li
        v-for="(item, index) in menu"
        :style="{ width: menuTabWidth }"
        :key="index"
        @click="selectMenu(item)"
      >
        <a
          href="javascript:;"
          :class="currentMenuItem == item ? 'active' : ''"
          >{{ item }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  nextTick,
  computed,
  ref,
  watch,
  onActivated,
} from "vue";
import usePageTab from "@/hooks/usePageTab";
import $ from "jquery";
import Swiper from "swiper";
export default defineComponent({
  name: "MenuTab",
  emits: ["change"],
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    swiper: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    initIndex: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    slidesPerView: {
      type: Number,
      default: () => {
        return 4;
      },
    },
  },
  setup(props, ctx) {
    let swiper: null | Swiper = null;

    const menuTabWidth = computed(() => {
      const width = Number.isNaN(props.slidesPerView)
        ? "25%"
        : Number(100 / Number(props.slidesPerView)).toFixed(2) + "%";
      return width;
    });
    function fixed(sticky: boolean) {
      if (sticky) {
        $(".menu-nav").css({
          position: "fixed",
          top: "50px",
        });
      } else {
        $(".menu-nav").css({
          position: "static",
        });
      }
    }
    const { currentMenuItem, selectMenu, updateCurrentMenuItem } = usePageTab(
      ctx,
      {
        menu: props.menu,
        selector: ".menu-nav-wapper",
        fixFunc: fixed,
        initIndex: props.initIndex,
      }
    );
    onMounted(() => {
      if (props.swiper) {
        swiper = new Swiper("#menu-nav-swiper", {
          initialSlide: props.initIndex,
          slidesPerView: props.slidesPerView,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
        });
      }
    });
    return {
      currentMenuItem,
      selectMenu,
      menuTabWidth,
    };
  },
});
</script>

<style lang="scss" scoped>
//  list  nav

.menu-nav-wapper {
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
  .menu-nav {
    overflow: hidden;
    height: 50px;
    padding-top: 5px;
    box-sizing: border-box;
    width: 100%;
    line-height: 45px;
    border-bottom: 1px solid #f4f4f4;
    top: 0;
    z-index: 10;
    background-color: #fff;
    a {
      display: block;
      text-align: center;
      line-height: 45px;
      width: 100%;
      font-size: 16px;
      color: #666;
    }
    .active {
      color: #b9135a;
    }
  }

  li {
    float: left;
    width: 25%;
    text-align: center;
    a {
      display: block;
      text-align: center;
      line-height: 45px;
      width: 100%;
      font-size: 16px;
      color: #666;
    }
  }

  .active {
    color: #b9135a;
  }
}
</style>