<template>
  <div class="product-list">
    <transition-group tag="ul" name="list">
      <li class="product-li" v-for="item in list.data" :key="item">
        <router-link :to="{ name: name, params: { id: item.id} }">
          <div class="product-img">
            <img :src="item.img" />
          </div>
          <div class="product-info-wrap">
            <div class="product-title">
              <span v-html="item.title"></span>
              <!-- <img
                v-if="tid"
                src="@/assets/images/icon-gw.png"
                @click.prevent="addShopCar(item.id, tid, 1, 0,0, item.stock)"
              /> -->
            </div>
            <div class="product-card-info" v-html="item.descInfo"  v-if="item.descInfo"></div>

             <!-- <div class="product-card-brand">
              <span v-if="item.specification">{{item.specification}}
                <template v-if="item.brand">;</template> {{item.brand}}</span>
              <span v-else>{{item.brand}}</span>
            </div> -->
          </div>
        </router-link>
      </li>
    </transition-group>
    <transition-group tag="ul" v-if="list.loading" name="list">
      <li class="product-li skeleton" v-for="item in 10" :key="item">
        <div class="product-img skeleton-bg"></div>
        <div class="product-info-wrap">
          <div class="product-title skeleton-bg"></div>
          <div class="product-card-info skeleton-bg"></div>
          <div class="product-card-brand skeleton-bg"></div>
        </div>
      </li>
    </transition-group>
  </div>
  <div v-if="list.data.length==0 && !list.loading && typeFlag=='search'" class="save-area">
    <router-link
      class="save"
      :to="{name: 'customize'}"
    >
      产品定制
    </router-link>
    <a href="javascript:;" @click="back">返回</a>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, isReactive, reactive, inject, watch } from "vue";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    list: Object,
    name: String,
    tid: {
      type: String,
      default: "",
    },
    anclassId: {
      type: String,
      default: "",
    },
    typeFlag: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()
    const router = useRouter();

    watch(
      () => {
        return props.list
      },
      (NewsVue) => {
        biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
      }
    )

    //  add shop car
    const { addShopCar } = useShopCar();
    function back() {
      router.go(-1);
    }
    return {
      addShopCar,
       userInfo,
      biaowuPriceOpen,
      back
    };
  },
});
</script>

<style lang="scss" scoped>
.product-ul {
  overflow: hidden;
}
// list data

.product-li {
  width: 100%;
  height: 100%;
  padding: 15px 10.012px;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f4f4;
  .product-img {
    width: 65px;
    height: 68px;
    float: left;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-info-wrap {
    margin-left: 75px;
  }
  .product-title {
    font-size: 14.8px;
    color: #111;
    line-height: 17.5px;
    // height: 32px;
    padding-right: 42.375px;
    box-sizing: border-box;
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;
    span{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp:1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    img {
      position: absolute;
      right: 23px;
      top: 0;
      width: 17px;
      height: 16px;
      z-index: 1;
    }
  }
  .product-card-info {
    font-size: 12px;
    color: #999;
    line-height: 16px;
    margin-top: 12px;
    // margin-bottom: 12px;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    // box-sizing: border-box;
    -webkit-line-clamp: 2;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .product-card-brand {
    color: #999;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    font-size: 12px;
    color: #999;
    line-height: 19px;
  }
  .price {
    color: #b9135a;
    margin-left: 10px;
  }


}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  .product-card-info {
    height: 16px;
    width: 70% !important;
  }
  .product-card-brand {
    height: 14px;
    width: 50% !important;
  }
  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}
</style>
